export type SnackbarConfig = {
   mode: 'dark' | 'light';
   message: string;
   duration: number;
   description: string;
   status: 'success' | 'error' | 'warning' | 'info';
   backButton: boolean;
   closeButton: boolean;
   action?: {
      showButton: boolean;
      buttonText: string;
   };
};

export const SNACKBAR_DEFAULT_STATE: SnackbarConfig = {
   mode: 'dark',
   message: 'successfully submitted',
   duration: 5000,
   description: '',
   status: 'success',
   backButton: false,
   closeButton: true,
   action: undefined
};
