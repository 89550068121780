<div
   class="rounded-pill shadow-md py-2 ps-2 pe-3 d-flex justify-content-center align-items-center"
   [ngClass]="{ 'bg-dark': config().mode === 'dark', 'bg-white': config().mode === 'light' }"
>
   @if (config().backButton) {
      <div class="d-flex g-2 align-items-center me-2">
         <i
            class="feather-arrow-left font-sm cursor-pointer"
            [ngClass]="{ 'text-grey-100': config().mode === 'dark', 'text-grey-600': config().mode === 'light' }"
            (click)="goBack()"
         ></i>
      </div>
   }
   <div class="me-2 d-flex align-items-center">
      @switch (config().status) {
         @case ('success') {
            <i class="feather-check-circle text-success fw-700 font-md"></i>
         }
         @case ('warning') {
            <i class="feather-info text-warning fw-700 font-md"></i>
         }
         @case ('info') {
            <i class="feather-info text-current fw-700 font-md"></i>
         }
         @case ('error') {
            <i class="feather-alert-circle text-danger fw-700 font-md"></i>
         }
      }
   </div>

   <div class="d-flex flex-column pe-2">
      <span
         class="font-xsss fw-500"
         [ngClass]="{ 'text-light': config().mode === 'dark', 'text-dark': config().mode === 'light' }"
         >{{ config().message }}</span
      >
      @if (config().description) {
         <span
            class="font-xssss fw-400 font-xsss"
            [ngClass]="{ 'text-grey-100': config().mode === 'dark', 'text-grey-600': config().mode === 'light' }"
            >{{ config().description }}</span
         >
      }
   </div>
   <div class="d-flex align-items-center">
      @if (config().action) {
         @if (config().action?.showButton) {
            <a class="cursor-pointer text-current font-xsss" (click)="actionClicked()">{{
               config().action?.buttonText
            }}</a>
         }
      }
      @if (config().closeButton) {
         <i
            class="feather-x font-xs cursor-pointer ms-2"
            [ngClass]="{ 'text-light': config().mode === 'dark', 'text-dark': config().mode === 'light' }"
            (click)="close()"
         ></i>
      }
   </div>
</div>
