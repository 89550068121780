import { ApplicationRef, ComponentRef, createComponent, inject, Injectable, output, signal } from '@angular/core';
import { SNACKBAR_DEFAULT_STATE, SnackbarConfig } from '../models/snackbar-config';
import { SnackContainerComponent } from '../components/snack-container/snack-container.component';
import { Subject } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class SnackbarService {
   isOpen = signal(false);
   globalConfig = signal<SnackbarConfig>(SNACKBAR_DEFAULT_STATE);
   private timeoutSubscription: any;

   onClose = new Subject<any>();

   private snackbarContainer!: HTMLElement;
   private snackbarComponentRef!: ComponentRef<SnackContainerComponent>;
   private appRef = inject(ApplicationRef);

   openSnackBar(config: Partial<SnackbarConfig> | null = null): SnackbarService {
      this.resetSnackBar();
      const tempConfig: any = { ...this.globalConfig() };

      if (config) {
         Object.keys(config).forEach(key => {
            const typedKey = key as keyof SnackbarConfig;
            const value = config[typedKey];

            if (value !== undefined) {
               tempConfig[typedKey] = value;
            }
         });
         this.globalConfig.set(tempConfig);
      }

      const hostElement = document.body;
      this.snackbarContainer = document.createElement('div');
      this.snackbarContainer.classList.add('host-snackbar');
      hostElement.appendChild(this.snackbarContainer);

      this.snackbarComponentRef = createComponent(SnackContainerComponent, {
         hostElement: this.snackbarContainer,
         environmentInjector: this.appRef.injector
      });
      this.appRef.attachView(this.snackbarComponentRef.hostView);
      this.snackbarComponentRef.changeDetectorRef.detectChanges();

      setTimeout(() => {
         this.isOpen.set(true);
      }, 50);

      this.timeoutSubscription = setTimeout(() => {
         this.closeSnackBar();
      }, this.globalConfig().duration);

      return this;
   }

   closeSnackBar(): void {
      this.onClose.next(null);
      this.destroySnackbar();
   }

   clickOnAction(): void {
      this.onClose.next(true);
      this.destroySnackbar();
   }

   private resetSnackBar(): void {
      this.clearTimeOut();
      this.snackbarComponentRef?.destroy();
      this.globalConfig.set(SNACKBAR_DEFAULT_STATE);
   }

   private destroySnackbar() {
      this.clearTimeOut();
      setTimeout(() => {
         this.snackbarComponentRef.destroy();
         this.globalConfig.set(SNACKBAR_DEFAULT_STATE);
      }, 600);
   }
   private clearTimeOut() {
      if (this.timeoutSubscription) {
         clearTimeout(this.timeoutSubscription);
      }
      this.isOpen.set(false);
   }
}
